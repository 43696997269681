<template>
  <div class="home" style="padding: 50px">
    <center>
      <div class="box">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <h1>登陆</h1>
              <small>需要登陆才可以访问受限制页面</small>
            </div>
          </div>
        </article>
      </div>
      <div
        class="card"
        style="
          width: 400px;
          margin-top: 10%;
        "
      >
        <div class="card-content">
          <b-field label="Email" :label-position="labelPosition">
            <b-input type="email" v-model="email" maxlength="30"> </b-input>
          </b-field>

          <b-field label="Password" :label-position="labelPosition">
            <b-input type="password" v-model="pass" password-reveal> </b-input>
          </b-field>
          <!-- <vue-recaptcha :sitekey="sitekey" @verify="onVerify"></vue-recaptcha> -->

        </div>
        <footer class="card-footer">
          <!-- <b-button type="is-primary" tag="router-link" :to="{ path: '/node' }" expanded>跳转</b-button> -->
          <b-button type="is-primary" @click="login" expanded>登陆</b-button>
        </footer>
      </div>
      <br />
      <br />
      <div>
        <small>没有账号？</small>
        <br />
        <b-button type="is-text" tag="router-link" :to="{ path: '/register' }"
          >注册</b-button
        >
      </div>
    </center>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { sitekey } from "../config.json";

export default {
  components: { VueRecaptcha },

  data() {
    return {
      // active: false,
      // triggers: ["hover"],
      // pagenum: null,
      email: "",
      pass: "",
      labelPosition: "on-border",
      VerRes: "",
      sitekey: sitekey,
    };
  },
  methods: {
    onVerify: function (response) {
      this.VerRes = response;
      console.log("Verify: " + response);
    },
    async login() {
      if (this.VerRes == "") {
        // return this.$buefy.dialog.alert("你还没有点验证码！");
      }
      let res = await this.$http.post("/api/auth", {
        email: this.email,
        password: this.pass,
      });
      if (res.status != 401 && res.data.access_token != null) {
        await localStorage.setItem("email", this.email);
        await localStorage.setItem("token", res.data.access_token);
      }
      console.log(await localStorage.getItem("email"));
      this.$router.push("/forum");
    },
  },
};
</script>

<style scoped>
</style>
