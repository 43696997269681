import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import VueLazyload from 'vue-lazyload'

import axios from "axios";
axios.defaults.withCredentials = true;
Vue.prototype.$http = axios

// Vue.use(VueLazyload)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: 'dist/error.png',
  // loading: 'dist/loading.gif',
  attempt: 1
})
Vue.use(Buefy)
Vue.use(mavonEditor);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
