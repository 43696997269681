import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/forum',
    name: 'Forum',
    component: () => import('../views/Forum')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register')
  },
  {
    path: '/post',
    name: 'Post',
    component: () => import('../views/Post')
  },
  {
    path: '/WritePost',
    name: 'WritePost',
    component: () => import('../views/WritePost')
  },
  {
    path: '/UploadGallery',
    name: 'UploadGallery',
    component: () => import('../views/UploadGallery')
  },
  {
    path: '/Gallery',
    name: 'Gallery',
    component: () => import('../views/Gallery')
  },
  {
    path: '/AllGallery',
    name: 'AllGallery',
    component: () => import('../views/AllGallery')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
